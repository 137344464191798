<script>
import Entrance from '../Entrance'
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  render() {
    return (
      <div class="entranceGroup">
        {this.data.map((e) => {
          return <Entrance item={e} on={this.$listeners} />
        })}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.entranceGroup {
  // width: calc(220px * 6);
  width: calc(100vw - 300px);
  display: flex;
  flex-wrap: wrap;
}
</style>
